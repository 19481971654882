.e-toolbar-left {
  left: 250px !important;
}

.e-cell {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.e-grid .e-headercell {
  padding-left: 8px !important;
  padding-right: 8px !important;
}