.e-invoiceParent {
  display: flex;
  justify-content: center;
}

.e-invoice {
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 6px 8px;
  background-color: #3b82f6;
  font-weight: 500;
  color: white;
  border-radius: 9999px;
}

.e-invoiceIcon {
  color: white;
  width: 18px;
  height: 18px;
}

.e-status {
  font-weight: 500 !important;
}

.e-statusPending {
  color: #f59e0b !important;
}

.e-statusPaid {
  color: #0ea5e9 !important;
}

.e-statusDone {
  color: #22c55e !important;
}

.e-statusRejected {
  color: #ef4444 !important;
}

.e-statusExpired {
  color: #ef4444 !important;
}